<template>
    <div class="container">
        <h1>Mise à jour des fournitures scolaires</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size65">
                    <label for="classes">Classe</label>
                    <select name="classes" class="form-control" id="classes-select" v-model="formData.classe">
                        <option disabled value="">--Sélectionnez une classe--</option>
                        <option value="PS">PS</option>
                        <option value="MS">MS</option>
                        <option value="GS">GS</option>
                        <option value="CP">CP</option>
                        <option value="CE1">CE1</option>
                        <option value="CE2">CE2</option>
                        <option value="CM1">CM1</option>
                        <option value="CM2">CM2</option>
                        <option value="ULIS">ULIS</option>
                    </select>
                </div>
                
                <div class="form-group size65 text-left">
                    <label for="file">Fichier Word</label>
                    <input type="file" ref="file" id="file" class="form-control-file" v-on:change="handleFileUpload()" />
                </div>
                
            </div>
   
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary" :disabled="isEnabled == 0" >Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div v-if="isLoading" >
                <img src="../assets/img/Color-Loader.gif" width="100" alt="Loading..." />
            </div>            
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    file: "",
                    classe: ""
                },                
                savingSuccessful: false,
                result: "",
                isLoading: false,
            }
        },
        methods: {
            submitData() {
                this.isLoading = true;
                const datas = new FormData();
                datas.append('file', this.formData.file);
                datas.append('classes', this.formData.classe);

                let self = this;

                axios
                    .post('/Supplies/AddListSupplies',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "La liste a été envoyée avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.savingSuccessful = false;
                        self.result = "Echec! Une erreur est survenue lors de l'envoi du fichier. (" + err + ")";
                    });
                this.isLoading = false;
            },
            handleFileUpload() {
                this.formData.file = this.$refs.file.files[0];
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }

        }
    }

</script>

<style scoped>
    .size65 {
        width:65%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  width: 7rem;
  display: inline-block;
  width: 20%;
  text-align: right;
}

form div {
  margin: 1rem 0;
}    
</style>