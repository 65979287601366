<template>
    <div class="container">
        <h1>Ajouter des Evènements</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size50">
                    <label for="title">Titre de l'évènement*</label>
                    <input type="text" v-model="formData.title" id="title" class="form-control" />
                </div>
                <div class="form-group size50">
                    <label for="from">En date du</label>
                    <input type="date" v-model="formData.from" id="from" class="form-control" required />
                </div>
                <div class="form-group size50">
                    <label for="comment">Libellé</label>
                    <input type="text" v-model="formData.comment" id="comment" class="form-control" required />
                </div>
            </div>
            <div class="my-4">
                <button v-on:click.prevent="submitData()" class="btn btn-primary">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    title: "",
                    from: "",
                    comment: ""
                },
                savingSuccessful: false,
                result: "",
                disabled: 0
            }
        },
        computed: {
            isDisabled: function() {
                return !(this.formData.title && this.formData.from && this.formData.comment);
            }
        },
        methods: {            
            submitData() {
                var datas = {
                    Title: this.formData.title,
                    From: this.formData.from,
                    Comment: this.formData.comment
                };

                let self = this;

                axios
                    .post('/Event/AddEvents',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "Le nouvel évènement a été inséré avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.savingSuccessful = false;
                        self.result = "Echec! Au moins l'un des champs doit être renseigné. (" + err + ")";
                    });
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }

        }
    }

</script>

<style scoped>
    .size50 {
        width: 50%;
        margin: auto;
    }

input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 35%;
  /* text-align: right; */
}
</style>