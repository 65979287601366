<template>
    <div class="container">
        <h1>Supprimer des Actualités APEL</h1>
        <ul class="mt-5">
            <li :key="index" v-for="(news, index) in listNews">
                <div class="card my-4 newsToDelete ">
                    <p><strong>{{news.Titre}}</strong></p>
                    <p class="marginContent">{{news.Contenu}}</p>
                    <div v-bind:id="index" v-on:click="confirmation(news.Id)" class="btn-suppr btn btn-danger"> X </div>
                </div>
            </li>
        </ul>
        <div>            
            <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
        </div>
        <div class="my-4">
            <p class="success">{{this.resultat}}</p>
        </div>
        <div class="modal" id="myModal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1>Etes-vous sûr?</h1>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        <button class="btn btn-danger" @click="suppression()">Supprimer</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import jQuery from 'jquery';
    let $ = jQuery;

    export default {
        name: 'SupprimerNews',
        data() {
            return {
                listNews: [],
                resultat: '',
                ident: ''
            }
        },
        methods: {
            getAllNews() {
                axios.get('/APELActualite/GetAllNews').then(response => {
                    this.listNews = response.data;
                });
            },
            confirmation(id) {                
                $("#myModal").modal('show');
                this.ident = id;
            },
            suppression() {
                const datas = new FormData();
                datas.append('id', this.ident);

                axios.post('/APELActualite/DeleteNews',  
                datas,               
                {                    
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    $("#myModal").modal('hide');
                    this.listNews = [];
                    this.getAllNews();
                    this.resultat = response.data;
                });                
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }
        },
        mounted() {

            //Get list of news
            this.getAllNews();

        }

    }

</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    .newsToDelete {
        position: relative;
        height: 88px;
    }
    .marginContent {
        margin: 0 45px;
    }
    .btn-suppr {
        position: absolute;
        top: 10px;
        right: 10px;
    }
</style>