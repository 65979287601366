<template>
    <div class="container">
        <h1>Ajouter des Congés</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size50">
                    <label for="label">Libellé du congé*</label>
                    <input type="text" v-model="formData.label" id="label" class="form-control" />
                </div>
                <div class="form-group size50">
                    <label for="from">A partir du (au soir)*</label>
                    <input type="date" v-model="formData.from" id="from" class="form-control" required />
                </div>
                <div class="form-group size50">
                    <label for="to">Jusqu'au (au matin)*</label>
                    <input type="date" v-model="formData.to" id="to" class="form-control" required />
                </div>
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary" :disabled="isDisabled">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    label: "",
                    from: "",
                    to: ""
                },
                savingSuccessful: false,
                result: "",
                disabled: 0
            }
        },
        computed: {
            isDisabled: function() {
                return !(this.formData.label && this.formData.from && this.formData.to);
            }
        },
        methods: {            
            submitData() {
                var datas = {
                    Label: this.formData.label,
                    From: this.formData.from,
                    To: this.formData.to
                };

                let self = this;

                axios
                    .post('/Holiday/AddHolidays',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "Le nouveau congé a été inséré avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.savingSuccessful = false;
                        self.result = "Echec! Au moins l'un des champs doit être renseigné. (" + err + ")";
                    });
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }

        }
    }

</script>

<style scoped>
    .size50 {
        width: 50%;
        margin: auto;
    }

    input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 35%;
  /* text-align: right; */
}
</style>