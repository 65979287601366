import Vue from 'vue';
import App from './App.vue';
import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueWow from 'vue-wow';

import axios from 'axios'
import router from './router'
import store from './store'

import Moment from 'vue-moment';

axios.defaults.baseURL = 'https://api.slbp.fr/api'	//'https://localhost:5001/api'
axios.defaults.headers.get['Accepts'] = 'application/json'

Vue.use(VueWow)
Vue.use(Moment)

new Vue({
	render: h => h(App),
	router: router,
	store: store
}).$mount('#app')
