<template>
    <div class="container">
        <h1>Ajouter des news APEL</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size65">
                    <label for="titre">Titre de la news</label>
                    <input type="text" v-model="formData.title" id="titre" class="form-control" />
                </div>
                <div class="form-group size65 mt-3">
                    <label for="contenu">Contenu de la news</label>
                    <textarea v-model="formData.content" id="contenu" class="form-control"></textarea>
                </div>
                <p><i>Le contenu doit avoir au moins 10 caractères</i></p>
                <div class="form-group size65 mt-3">
                    <label for="auteur">Auteur de la news</label>
                    <input type="text" v-model="formData.author" id="auteur" class="form-control" />
                </div>
                <div class="form-group size65 mt-3">
                    <label for="file">Image de la news</label>
                    <input type="file" ref="file" id="file" class="form-control-file" v-on:change="handleFileUpload()" />
                </div>
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    file: "",
                    title: "",
                    content: "",
                    author: ""
                },
                savingSuccessful: false,
                result: ""
            }
        },
        methods: {
            submitData() {
                const datas = new FormData();
                datas.append('file', this.formData.file);
                datas.append('titre', this.formData.title);
                datas.append('contenu', this.formData.content);
                datas.append('auteur', this.formData.author);

                let self = this;

                axios
                    .post('/APELActualite/AddNews',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "La nouvelle news a été insérée avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.savingSuccessful = false;
                        self.result = "Echec! Veuillez recommencer. (" + err + ")";
                    });
            },
            handleFileUpload() {
                this.formData.file = this.$refs.file.files[0];
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }

        }
    }

</script>

<style scoped>
    .size65 {
        width:65%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 25%;
  text-align: right;
}

</style>