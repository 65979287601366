import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth'
//import globalAxios from 'axios'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken: null,
    userName: null,
    userRole: null
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.userName = userData.userName
      state.userRole = userData.userRole
    },
    // storeUser (state, user) {
    //   state.user = user
    // },
    clearAuthData (state) {
      state.idToken = null
      state.userName = null
      state.userRole = null
    }
  },
  actions: {
    // signup ({commit, dispatch}, authData) {
    //   axios.post('/signupNewUser?key=AIzaSyCXlVPPWknVGhfc60mt7Jkv0Xzrho7_mwc', {
    //     email: authData.email,
    //     password: authData.password,
    //     returnSecureToken: true
    //   })
    //     .then(res => {
    //       console.log(res)
    //       commit('authUser', {
    //         token: res.data.idToken,
    //         userId: res.data.localId
    //       })
    //       dispatch('storeUser', authData)
    //     })
    //     .catch(error => console.log(error))
    // },
    setLogoutTimer ({commit}, expirationTime) {
        setTimeout(() => {
          commit('clearAuthData')
          localStorage.removeItem('expirationDate')
          localStorage.removeItem('token')
          localStorage.removeItem('userName')
          localStorage.removeItem('userRole')
        }, expirationTime * 1000)
    },
    login ({commit, dispatch}, authData) {
      axios.post('/auth/login', {
        username: authData.email,
        password: authData.password
      })
        .then(res => {
            const now = new Date()
            const expirationDate = new Date(now.getTime() + (20 * 60 * 1000))
            localStorage.setItem('token', res.data.ResponseToken)
            localStorage.setItem('userName', res.data.ResponseUserName)
            localStorage.setItem('userRole', res.data.ResponseRole)
            localStorage.setItem('expirationDate', expirationDate)
            commit('authUser', {
                token: res.data.ResponseToken,
                userName: res.data.ResponseUserName,
                userRole: res.data.ResponseRole
          })
          dispatch('setLogoutTimer', (20 * 60))
          router.replace({ name: "accueil" })
        })
        .catch(error => console.log(error))
    },
    tryAutoLogin ({commit}) {
      const token = localStorage.getItem('token')
      if (!token) {
        return
      }
      const expirationDate = localStorage.getItem('expirationDate')
      const now = new Date()
      if (now >= new Date(expirationDate)) {
        return
      }
      const userName = localStorage.getItem('userName')
      const userRole = localStorage.getItem('userRole')
      
      commit('authUser', {
        token: token,
        userName: userName,
        userRole: userRole
      })
    },
    logout ({commit}) {
      commit('clearAuthData')
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('token')
      localStorage.removeItem('userName')
      localStorage.removeItem('userRole')
      router.replace('/')
    },
    // storeUser ({commit, state}, userData) {
    //   if (!state.idToken) {
    //     return
    //   }
    //   globalAxios.post('/users.json' + '?auth=' + state.idToken, userData)
    //     .then(res => console.log(res))
    //     .catch(error => console.log(error))
    // },
    // fetchUser ({commit, state}) {
    //   if (!state.idToken) {
    //     return
    //   }
    //   globalAxios.get('/users.json' + '?auth=' + state.idToken)
    //     .then(res => {
    //       console.log(res)
    //       const data = res.data
    //       const users = []
    //       for (let key in data) {
    //         const user = data[key]
    //         user.id = key
    //         users.push(user)
    //       }
    //       console.log(users)
    //       commit('storeUser', users[0])
    //     })
    //     .catch(error => console.log(error))
    // }
  },
  getters: {
    user (state) {
      return state.userName
    },
    role (state) {
        return state.userRole
    },
    isAuthenticated (state) {
      return state.idToken !== null
    }
  }
})