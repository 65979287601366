<template>
    <div class="container">
        <div>
            <div class="left">{{user}} est connecté</div>
            <div class="right">
                <button class="btn btn-warning" v-on:click="logOut">Logout</button>
            </div>
        </div>
        <img class="my-5"  data-src="../../assets/img/SLBP_logo.jpg" src="../assets/img/SLBP_logo.jpg" alt="Ecole Saint Louis Blaise Pascal, Choisy-le-Roi" />
        <div v-if="hasAdminRight || hasDirectionRight" class="listCards mb-2">
            <div class="card mh-225">
                <router-link to="/ajouterNews">
                    <div class="adminAction my-2">Ajouter des Actualités</div>
                    <div><img class="mt-4" src="../assets/img/news.jpg"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/supprimerNews">
                    <div class="adminAction my-2">Supprimer des Actualités</div>
                    <div><img class="mt-4" src="../assets/img/bin.png"></div>
                </router-link>
            </div>
        </div>
        <div v-if="hasAdminRight || hasDirectionRight" class="listCards mb-2">
            <div class="card mh-225">
                <router-link to="/addMenu">
                    <div class="adminAction my-2">Ajouter un menu cantine</div>
                    <div><img class="mt-4" src="../assets/img/menu.jpg"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/ajouterConges">
                    <div class="adminAction my-2">Ajouter des vacances</div>
                    <div><img class="mt-4" src="../assets/img/holiday.png"></div>
                </router-link>
            </div>            
        </div>
        <div v-if="hasAdminRight || hasDirectionRight" class="listCards mb-2">
            <div class="card mh-225">
                <router-link to="/ajouterEvenements">
                    <div class="adminAction my-2">Ajouter des évènements</div>
                    <div><img class="mt-4" src="../assets/img/event.jpg"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/supprimerEvenements">
                    <div class="adminAction my-2">Supprimer des évènements</div>
                    <div><img class="mt-4" src="../assets/img/bin.png"></div>
                </router-link>
            </div>            
        </div>
        <div v-if="hasAdminRight || hasDirectionRight" class="listCards mb-2">
            <div class="card mh-225">
                <router-link to="/ajouterDossierInscription">
                    <div class="adminAction my-2">Dossier d'inscription</div>
                    <div><img class="mt-4" src="../assets/img/subscription.png"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/ajouterListeFournitures">
                    <div class="adminAction my-2">Fournitures</div>
                    <div><img class="mt-4" src="../assets/img/supplies.jpg"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/ajouterTarif">
                    <div class="adminAction my-2">Tarifs</div>
                    <div><img class="mt-4" src="../assets/img/tarif.jpg"></div>
                </router-link>
            </div>            
        </div>
        <div v-if="hasAdminRight || hasAPELRight" class="listCards mb-2">        
            <div class="card mh-225">
                <router-link to="/ajouterNewsAPEL">
                    <div class="adminAction my-2">Ajouter des infos APEL</div>
                    <div><img class="mt-4" src="../assets/img/infoApel.png"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/supprimerAPELNews">
                    <div class="adminAction my-2">Supprimer des infos APEL</div>
                    <div><img class="mt-4" src="../assets/img/bin.png"></div>
                </router-link>
            </div>
        </div>
        <div v-if="hasAdminRight || hasAPELRight" class="listCards mb-2">        
            <div class="card mh-225">
                <router-link to="/ajouterDateAPEL">
                    <div class="adminAction my-2">Ajouter des dates APEL</div>
                    <div><img class="mt-4" src="../assets/img/eventApel.jpg"></div>
                </router-link>
            </div>
            <div class="card mh-225">
                <router-link to="/supprimerDateAPEL">
                    <div class="adminAction my-2">Supprimer des dates APEL</div>
                    <div><img class="mt-4" src="../assets/img/bin.png"></div>
                </router-link>
            </div>            
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminAccueil',
        data() {
            return {
                user: this.$store.getters.user,
                userRole: this.$store.getters.role,
            }
        },
        computed: {
            hasAdminRight() {return this.userRole === "Administrateur";},
            hasDirectionRight() {return this.userRole === "Direction";},
            hasAPELRight() {return this.userRole === "APEL";}
        },
        methods: {
            logOut() {
                this.$store.dispatch('logout');
            }
        }
    }

</script>

<style scoped>
    /* ul {
        list-style-type: none;
        padding: 0;
        display: flex;
    } */

    .listCards {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .mh-225 {
        height: 225px;
        margin: 0 20px;
        width: 180px;
    }

    a:hover {
        text-decoration: none;
    }

    .adminAction {
        height: 70px;
        font-size: 20px;
        padding: 20px;
        text-align: center;
    }

    .adminAction:hover {
        color: forestgreen;
        text-decoration: none;
    }

    .left {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .right {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .size50 {
        width: 50%;
        margin: auto;
    }
</style>