<template>
    <div class="container">
        <h1>Ajouter un menu de Cantine</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size50">
                    <label for="titre">Date du lundi du menu</label>
                    <input type="date" v-model="formData.lundi" id="titre" class="form-control" />
                </div>
                <div class="form-group size50">
                    <label for="file">Fichier Excel</label>
                    <input type="file" ref="fichier" id="fichier" class="form-control-file" v-on:change="handleFileUpload()" />
                </div>
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.resultat}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    fichier: "",
                    lundi: ""
                },
                resultat: "",
                savingSuccessful: false
            }
        },
        methods: {
            submitData() {
                const datas = new FormData();
                datas.append('file', this.formData.file);
                datas.append('lundi', this.formData.lundi);

                let self = this;

                axios
                    .post('/Menu/AddMenu',
                        datas,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function () {
                        console.log('SUCCESS!!');   // eslint-disable-line no-console
                        self.savingSuccessful = true;
                        self.resultat = "Le menu a bien été sauvegardé!";
                    })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.resultat = "Echec! Une erreur est survenue (" + err + ")";
                        self.savingSuccessful = false;
                    });
            },
            handleFileUpload() {
                this.formData.file = this.$refs.fichier.files[0];
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }
        }
    }
    
</script>

<style scoped>
    .size50 {
        width: 50%;
        margin: auto;
    }

        input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 35%;
  /*  text-align: right; */
}
</style>