import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import LoginPage from './components/LoginPage';
import AdminAccueil from './components/AdminAccueil';
import AddNews from './components/AddNews';
import SupprimerNews from './components/SupprimerNews';
import AddMenu from './components/AddMenu';
import AddAPELNews from './components/AddAPELNews';
import SupprimerAPELNews from './components/SupprimerAPELNews';
import AddHolidays from './components/AddHolidays';
import AddEvents from './components/AddEvents';
import SupprimerEvenements from './components/SupprimerEvent';
import AddSubscriptionFolder from './components/AddSubscriptionFolder';
import AddTarif from './components/AddTarif';
import AddSupplies from './components/AddSupplies';
import AddAPELDate from './components/AddAPELDate';
import SupprimerApelEvent from './components/SupprimerApelEvent';

Vue.use(VueRouter)

const routes = [
  { path: '/', component: LoginPage },
  { path: '/administration', 
    name: 'accueil',
    component: AdminAccueil,
    beforeEnter (to, from, next) {
        //console.log('token ' + store.state.idToken) 
      if (localStorage.getItem('token')) {            
        next()
      } else {
        next('/')
      }
    }
  },
  { path: '/ajouterNews', 
    component: AddNews,
    beforeEnter (to, from, next) {
      if (store.state.idToken) {
        next()
      } else {
        next('/')
      }
    }
  },
  { path: '/supprimerNews', 
    component: SupprimerNews,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
            next()
        } else {
            next('/')
        }
    }
  },
  { path: '/addMenu', 
    component: AddMenu,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/ajouterConges',
    component: AddHolidays,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/ajouterEvenements',
    component: AddEvents,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
            next()
        } else {
            next('/')
        }
    }
  },
  { path: '/supprimerEvenements', 
  component: SupprimerEvenements,
  beforeEnter (to, from, next) {
      if (store.state.idToken) {
          next()
      } else {
          next('/')
      }
  }
},
  { path: '/ajouterDossierInscription',
    component: AddSubscriptionFolder,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/ajouterTarif',
    component: AddTarif,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },  
  { path: '/ajouterListeFournitures',
    component: AddSupplies, 
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },  
  { path: '/ajouterNewsAPEL',
    component: AddAPELNews,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/supprimerAPELNews',
    component: SupprimerAPELNews,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/ajouterDateAPEL',
    component: AddAPELDate,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
        next()
        } else {
        next('/')
        }
    }
  },
  { path: '/supprimerDateAPEL',
    component: SupprimerApelEvent,
    beforeEnter (to, from, next) {
        if (store.state.idToken) {
            next()
        } else {
            next('/')
        }
    }
  },  
]

export default new VueRouter({mode: 'hash', base: process.env.BASE_URL, routes})