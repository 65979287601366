<template>
    <div class="container">
        <h1>Ajouter des Dates APEL</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size65">
                    <label for="label">Libellé de l'évènement*</label>
                    <input type="text" v-model="formData.label" id="label" class="form-control" />
                </div>
                <div class="form-group size65">
                    <label for="from">Date de l'évènement*</label>
                    <input type="date" v-model="formData.date" id="date" class="form-control" required />
                </div>
                <div class="form-group size65">
                    <label for="to">Informations</label>
                    <input type="text" v-model="formData.info" id="info" class="form-control" />
                </div>
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary" :disabled="isDisabled">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddAPELDate',
        data() {
            return {
                formData: {
                    label: "",
                    date: "",
                    info: ""
                },
                savingSuccessful: false,
                result: "",
                disabled: 0
            }
        },
        computed: {
            isDisabled: function() {
                return !(this.formData.label && this.formData.date);
            }
        },
        methods: {            
            submitData() {
                var datas = {
                    Label: this.formData.label,
                    Date: this.formData.date,
                    Info: this.formData.info
                };

                let self = this;

                axios
                    .post('/APELDate/AddAPELDate',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "La nouvelle date a été insérée avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.savingSuccessful = false;
                        self.result = "Echec! Au moins l'un des champs doit être renseigné. (" + err + ")";
                    });
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }

        }
    }

</script>

<style scoped>
    .size65 {
        width:65%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 25%;
  text-align: right;
}
</style>