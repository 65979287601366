<template>
    <div>
        <img class="my-5" src="../assets/img/logo-school.png" alt="Ecole Saint Louis Blaise Pascal, Choisy-le-Roi" />
        <div class="form-group size50">
            <label for="username">Login</label>
            <input type="text" v-model="username" name="username" class="form-control" />
            <div v-show="submitted && !username" class="invalid-feedback">Le login est obligatoire</div>
        </div>
        <div class="form-group size50">
            <label htmlFor="password">Password</label>
            <input type="password" v-model="password" name="password" v-on:keyup="validateForm" class="form-control" />
            <div v-show="submitted && !password" class="invalid-feedback">Le mot de passe est obligatoire</div>
        </div>
        <div class="form-group">
            <button class="btn btn-primary m-2" v-on:click="login()" v-on:keyup.enter="login()">Connecter</button>
            <div v-if="isLoading" >
                <img src="../assets/img/Color-Loader.gif" width="100" alt="Loading..." />
            </div>
        </div>
        <div>
            <p class="red">
                {{this.errorMessage}}
            </p>
        </div>
    </div>
</template>
<script>

export default {
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
            token: '',
            errorMessage: '',
            isLoading: false
        }
    },
    methods: {
        validateForm: function (e) {
            if (e.keyCode === 13) {
                //Enter
                this.login();
            }
        },
        login () {
            this.isLoading = true;
            this.submitted = true;

            this.$store.dispatch('login', {email: this.username, password: this.password})
            this.isLoading = false;
            
        }
    }
};
</script>
<style scoped>
    .size50 {
        width: 50%;
        margin: auto;
    }
</style>
