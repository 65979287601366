<template>
    <div class="container">
        <h1>Mettre à jour le dossier<br />de pré-inscription</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size65">
                    <label for="file">Fichier Word</label>
                    <input type="file" ref="fichier" id="fichier" class="form-control-file" v-on:change="handleFileUpload()" />
                </div>
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary">Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.resultat}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddSubscriptionFolder',
        data() {
            return {
                formData: {
                    fichier: ""
                },
                resultat: "",
                savingSuccessful: false
            }
        },
        methods: {
            submitData() {
                const datas = new FormData();
                datas.append('file', this.formData.fichier);

                let self = this;

                axios
                    .post('/Inscription/AddFile',
                        datas,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function () {
                        console.log('SUCCESS!!');   // eslint-disable-line no-console
                        self.savingSuccessful = true;
                        self.resultat = "Le fichier a bien été sauvegardé!";
                    })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        self.resultat = "Echec! Une erreur est survenue (" + err + ")";
                        self.savingSuccessful = false;
                    });
            },
            handleFileUpload() {
                this.formData.fichier = this.$refs.fichier.files[0];
            },
            goAccueil: function () {
                this.$router.push('/administration');
            }
        }
    }
    
</script>

<style scoped>
    .size65 {
        width:65%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    input {
  font: inherit;
  padding: 0.15rem;
}

label {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 20%;
  /* text-align: right; */
}
</style>