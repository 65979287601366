<template>
    <div class="container">
        <h1>Ajouter des Actus</h1>

        <form class="mt-5">
            <div v-if="!savingSuccessful">
                <div class="form-group size65">
                    <label class="libelle" for="titre">Titre de l'actualité</label>
                    <input type="text" v-model="formData.title" id="titre" class="form-control" />
                </div>
                <div class="form-group size65 mt-3">
                    <label class="libelle" for="contenu">Contenu de l'actualité</label>
                    <textarea v-model="formData.content" id="contenu" class="form-control"></textarea>                    
                </div>
                <p><i>Le contenu doit avoir au moins 10 caractères</i></p>
                <div class="form-group size65 mt-3">
                    <label class="libelle" for="auteur">Auteur</label>
                    <input type="text" v-model="formData.author" id="auteur" class="form-control" />
                </div>
                <div class="form-group size65 mt-3">
                    <label class="libelle" for="file">Image de l'actualité</label>
                    <input type="file" ref="file" id="file" class="form-control-file" v-on:change="handleFileUpload()" />
                </div>
                <div>
                    <p class="my-4"><i>Au moins un des champs ci-dessus doit être renseigné</i></p>
                </div> 
                <div class="row justify-content-center"> 
                    <div class="col-md-5 text-left"> 
                        <fieldset>  
                        <legend>Ajouter un lien cliquable:</legend>
                        <!-- Group of default radios - option file -->
                        <div class="custom-control custom-radio">
                            <input type="radio" class="custom-control-input" id="groupFileLink" name="groupOfLinkRadios" checked @click="linkSelected()">
                            <label class="custom-control-label" for="groupFileLink">vers un fichier à télécharger</label>
                        </div>

                        <!-- Group of default radios - option web -->
                        <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" id="groupWebLink" name="groupOfLinkRadios"  @click="linkSelected()">
                        <label class="custom-control-label" for="groupWebLink">vers une page du web</label>
                        </div>
                        </fieldset>
                    </div>
                    <div class="col-md-6 mx-auto" style="display: block" id="fileLink">
                        <label class="linkLabel" for="linkFile">Télécharger le fichier Word</label>
                        <input type="file" ref="linkFile" id="linkFile" class="form-control-file w-80" v-on:change="handleLinkFileUpload()" />
                        <p><i>Le fichier doit contenir au maximum 3 pages</i></p>
                    </div>
                    <div class="col-md-7 mx-auto" id="webLink" style="display: none">   
                        <label class="linkLabel" for="linkWeb" style="padding-top: 8px; ">Adresse web</label>
                        <input type="text" v-model="formData.link" id="linkWeb" class="form-control w-80" />   
                        <p><i>L'adresse doit débuter par http</i></p>                 
                    </div>  
                </div>                
            </div>
   
            <div v-if="!savingSuccessful" class="my-4">
                <div class="form-group size65">
                    <input type="checkbox" v-model="formData.isAlert" @click="isCbClicked()" id="alert" name="alert">
                    <label class="cbLabel" for="alert">Cette actualité est associée à une alerte</label>
                </div>
                <div v-if="isAnAlert" class="form-group size65">
                    <label for="auteur">Message de l'alerte*</label>
                    <input type="text" v-model="formData.alertMsg" id="alerteMsg" class="form-control" />
                </div>                
            </div>
            <div class="my-4">
                <button v-if="!savingSuccessful" v-on:click.prevent="submitData()" class="btn btn-primary" :disabled="isEnabled == 0" >Envoyer</button>
                <button v-on:click="goAccueil()" class="btn btn-warning ml-2">Retour</button>
            </div>
            <div v-if="isLoading" >
                <img src="../assets/img/Color-Loader.gif" width="100" alt="Loading..." />
            </div>            
            <div class="message" :class="savingSuccessful ? 'success' : 'error'">
                <p>{{this.result}}</p>
            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AddNews',
        data() {
            return {
                formData: {
                    file: "",
                    title: "",
                    content: "",
                    author: "",
                    fileLink: "",
                    link: "",
                    isAlert: false,
                    alertMsg: ""
                },
                isAnAlert: false,
                savingSuccessful: false,
                result: "",
                isLoading: false,
            }
        },
        computed: {
            isEnabled () { 
                            if (!this.isAnAlert || (this.isAnAlert && this.formData.alertMsg)) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
        },
        methods: {
            isCbClicked() {
                this.isAnAlert = ! this.isAnAlert;
            },
            submitData() {
                this.isLoading = true;
                const datas = new FormData();
                datas.append('file', this.formData.file);
                datas.append('titre', this.formData.title);
                datas.append('contenu', this.formData.content);
                datas.append('auteur', this.formData.author);
                datas.append('fileLink', this.formData.fileLink);
                datas.append('link', this.formData.link);
                
                if(this.formData.isAlert) {
                    datas.append('msgAlerte', this.formData.alertMsg);
                }

                let self = this;

                axios
                    .post('/Actualite/AddNews',
                    datas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'), 
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function () {
                    console.log('SUCCESS!!');   // eslint-disable-line no-console
                    self.savingSuccessful = true;
                    self.result = "La nouvelle actualité a été créée avec succès!";
                })
                    .catch(function (err) {
                        console.log('FAILURE!! ' + err);    // eslint-disable-line no-console
                        console.log(err.response.data);
                        self.savingSuccessful = false;
                        self.result = "Echec! L'actualité n'a pas pu être créée. (" + err.response.data.ExceptionMessage + ")";
                    });
                this.isLoading = false;
            },
            handleFileUpload() {
                this.formData.file = this.$refs.file.files[0];
            },
            handleLinkFileUpload() {
                this.formData.fileLink = this.$refs.linkFile.files[0];
                this.formData.link  = this.$refs.linkFile.files[0].name;

            },
            goAccueil: function () {
                this.$router.push('/administration');
            },
            linkSelected: function() {
                var ele = document.getElementsByName('groupOfLinkRadios');
                
                for(var i = 0; i < ele.length; i++) {
                    if(ele[i].checked) {
                        if(ele[i].id == "groupWebLink") {
                            document.querySelector("#fileLink").style.display = "none";
                            document.querySelector("#webLink").style.display = "block";
                        } else {
                            document.querySelector("#fileLink").style.display = "block";
                            document.querySelector("#webLink").style.display = "none";
                        }
                    }
                }       
            }

        }
    }

</script>

<style scoped>
    .size65 {
        width:65%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    input {
  font: inherit;
  padding: 0.15rem;
}

.libelle {
  font-weight: bold;
  margin-right: 1rem;
  display: inline-block;
  width: 25%;
  text-align: right;
}

.cbLabel {
    width: 100%;
    text-align: left;
    margin-left: 1rem;
}

.linkLabel {
    font-weight: bold;
    width: 125px;
    text-align: left;
    display: table-cell;
            width: 1px;
            white-space: nowrap;
}

.w-80 {
    width: 60%;
}
</style>